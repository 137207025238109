.footer-section{
  padding: 80px 0 104px;
  background-color: #131A27;
}

.footer-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-container{
  width: calc(50% - 20px);
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  margin-top: 104px;
}

.footer-container.sample{
  width: 100%;
  padding: 0 calc(50% - 280px) 219px;
  text-align: center;
  justify-content: center;
  margin: 0;
}

.sample-background{
  position: absolute;
  top: -104px;
  width: 100vw;
  left: calc(-100vw / 2 + calc(50% - 280px) / 2);
  right: calc(-100vw / 2 + calc(50% - 280px) / 2);
  margin: 0 auto;
  z-index: -2;
  background-color: #0C111A;
}

.waves{
  position: absolute;
  margin-left: calc(-100vw / 2 + 560px / 2);
  margin-right: calc(-100vw / 2 + 560px / 2);
  bottom: 0;
  z-index: -1;
  min-height: 300px;
  min-width: 100vw;
}

.footer-title{
  text-transform: capitalize;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.footer-icon{
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

.share-container{
  width: 100%;
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.share-content{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.share-button{
  color: #FFFFFF;
  background-color: rgba(250,250,250,.2);
  border: none;
  border-radius: 50%;
  background-size: 22px;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  margin: 0 24px 0 0;
  position: relative;
  background-position: center;
  background-size: 22.5px;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.share-button:hover{
  background-color: rgba(250,250,250,.4);
}

.download-container{
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 32px 0;
}

.sample-data-input{
  height: 46px;
  position: relative;
  width: 240px;
  background-color: rgba(250,250,250,0.2);
  border-radius: 4px;
  border: 1px solid #BFC5D2;
  color: #FAFAFA;
  padding: 0 16px;
  margin-right: 8px;
}

.sample-data-input:focus{
  border: 1px solid #FAFAFA;
  outline: none !important;
}

.sample-data-input::placeholder{
  color: #B0BAC9
}

.sample-data-button{
  background-color: #114FCB;
  border-radius: 4px;
  height: 48px;
  width: 240px;
  padding: 16px;
  text-transform: capitalize;
  border: none;
  color: #FFFFFF;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.sample-data-button:hover{
  background-color: #00349E;
}

.form-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}

.success-container {
  opacity: 1;
  transition: all 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  position: relative;
  z-index: 10;
  background-color: transparent;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #FAFAFA;
}

.success-container a{
  color: #2b7bfc;
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer-container{
    width: auto !important;
    margin: 40px 0;
  }

  .footer-container.sample{
    padding: 0 0 85px;
  }

  .waves{
    margin: 0 !important;
    left: calc(160px - 50vw) !important;
    opacity: .5;
  }

  .sample-data-input{
    width: 288px !important;
    margin: 0 0 8px;
  }

  .sample-data-button{
    width: 320px !important;
  }

  .form-container{
    justify-content: center;
  }

  .footer-content{
    margin: 0;
  }
}

@media screen and (min-width:768px) and (max-width: 1439px) {
  .footer-container{
    width: 560px !important;
    margin: 40px 0;
  }

  .footer-container.sample{
    padding: 0 0 219px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1439px) {
  .footer-section{
    padding-bottom: 80px;
  }
  .footer-content{
    max-width: none;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .share-section{
    text-align: left;
    width: auto;
  }

  .share-container{
    flex-direction: column;
    width: fit-content;
  }

  .sharing-container{
    display: flex;
    justify-content: flex-start;
  }

  .share-content{
    width: auto;
    text-align: justify;
  }

  .share-title{
    margin-bottom: 32px;
  }

  .download-container{
    flex-direction: column;
  }

  .download-button{
    margin: 0 0 40px;
    width: fit-content;
  }
}
