.section-control{
  height: fit-content;
  position: relative;
  display: flex;
  width: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 8px;
  justify-content: space-between;

}

.section-control-button{
  position: relative;
  display: block;
  background-color: transparent;
  border: none;
  width: 100%;
  height: fit-content;
  margin: 8px 8px;
  color: #FFFFFF;
  opacity: .5;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.section-name{
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #FFFFFF;
}

.section-name.mobile{
  display: none;
  position: absolute;
  width: fit-content;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.section-control-button.selected, .section-control-button:hover{
  opacity: 1;
  padding-top: 8px;
}

.section-progress{
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 2px;
  position: relative;
  margin-top: 8px;
}

.section-progress .bar{
  background-color: #FFFFFF;
  width: 0;
  max-width: 100%;
  height: 100%;
  position: relative;
  transition: all .1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .section-control{
    height: 40px;
  }

  .section-control-button{
    position: relative;
    display: flex;
    align-content: flex-end;
    width: 100%;
    margin: 8px 8px;
  }


  .section-progress{
    bottom: 0;
  }

  .section-name{
    display: none;
  }

  .section-control-button.selected .section-name{
    display: none;
  }

  .section-name.mobile{
    display: flex;
  }

  .section-control-button{
    margin: 0 4px !important;
    padding: 0;
    height: 100%;
  }
}
