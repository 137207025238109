.home-view{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.hero-container{
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  opacity: 1;
  overflow: hidden;
}

.hero-container .image{
  width:100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .75s cubic-bezier(1, 1, 0, 0);
  z-index: -1;
}

.video-view{
  min-width: 100vw;
  height: auto;
  min-height: 100vh;
  position: fixed;
  background-color: #0C111A;
  top: 0;
  left: -50%;
  right: -50%;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: auto;
}

.hero-content{
  position: absolute;
  height: fit-content;
  top: calc(33% - 73px);
  left: 0;
  right: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  margin: 0 auto;
  align-items:  center;
}

.hero-content .sub-header{
  text-transform: uppercase;
}

.hero-content .header{
  padding: 8px 0 24px ;
  white-space:
}

.hero-content .body{
  opacity: 0.8;
  color: #FFFFFF;
  padding-bottom: 40px;
}

.cta-container{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.cta{
  height: 48px !important;
  width: 280px !important;
  border-radius: 3px;
  border: none;
  text-transform: capitalize;
  margin: 12px;
  align-items: center;
  color: #FAFAFA;
}

.cta.main{
  background-color: #114FCB;;
}

.cta.main:hover{
  background-color: #00349E;
}

.cta.secondary{
  background-color: rgba(250,250,250,.2);
}

.cta.secondary:hover{
  background-color: rgba(250,250,250,.4);
}

.progress-container{
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 4;
  min-width: 108px;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.progress-container .button{
  height: 24px;
  opacity: 0.8;
  text-transform: capitalize;
  background-color: transparent;
  text-align: left;
  margin: 0 0 8px 0;
  padding: 0;
  opacity: .6;
}

.progress-container .button:hover{
  opacity: 1;
}

.progress-container .progress-bar{
  width: 108px;
  height: 4px;
  background-color: rgba(250,250,250, .4);
  overflow: hidden;
  border-radius: 2px;
  position: relative;
}

.progress-container .progress-status{
  position: absolute;
  height: 4px;
  width: 108px;
  right: 100%;
  background-color: #FAFAFA;
  border-radius: 2px;
  animation-name: progress;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1439px){
  .hero-content{
    width: 80%;
  }

  .hero-content .body{
    width: 69%;
  }

  .hero-content .cta{
    width: 69%;
  }
}

@media screen and (max-width: 767px) {
  .home-view{
    overflow: none scroll;
  }

  .hero-content{
    width: calc(100vw - 48px)
  }

  .hero-content .sub-header{
    width: 100%;
  }

  .hero-content .header{
    padding: 8px 0 24px 0;
  }

  .hero-content .cta{
    height: fit-content;
    width: 100%;
    padding: 0;
    text-overflow: ellipsis;
  }

  .video-view{
    height: 100%;
    width: auto;
    position: fixed;
    top: 0;
    left: -25%;
    transform: translate(-25%, 0);
    display: flex;
    margin: auto;
  }
}


@keyframes progress  {
  to{
    right: 0;
  }
}
