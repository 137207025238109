.contact-us{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 100;
  overflow-y: scroll;
}

.background-cover{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(12,17,26,.8);
  width: 100vw;
  height: 100vh;
  display: flex;
}


.contact-form{
  background-color: #FFFFFF;
  height: fit-content;
  width: 672px;
  margin: auto;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.contact-header{
  color: #0C111A;
  width: auto;
  text-align: center;
  margin: 64px 0 8px;
  text-transform: capitalize;
}

.contact-message{
  color: #0C111A;
  width: auto;
  padding: 0 110px;
  text-align: center;
  margin-bottom: 32px;
}

.forms-container{
  width: auto;
  height: fit-content;
  margin: 0 40px 40px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.contact-column{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.contact-column.message{
  margin-left: 48px;
  height: auto;
}

.input-container{
  margin-bottom: 32px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-header{
  text-transform: uppercase;
  margin-bottom: 8px;
}

.contact-input{
  height: 24px;
  max-height: 40px;
  width: 240px;
  border: 1px solid #B0BAC9;
  border-radius: 5px;
  background-color: rgba(176,186,201,0.2);
  padding: 8px 16px;
  position: relative;
  display: flex;
  color: #0C111A;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.contact-input:focus{
  border: 1px solid #FAFAFA;
  outline: none !important;
}

.contact-input::placeholder{
  color: #B0BAC9;
}

.error-message.contact{
  position: absolute;
  top: 100%;
}

.error-message.form{
  position: absolute;
  top: 100%;
  width: 100%;
  justify-content: center;
  font-family: "Museo Sans";
}

.input-message{
  width: auto;
  border: 1px solid #B0BAC9;
  border-radius: 5px;
  background-color: rgba(176,186,201,0.2);
  height: 100%;
  padding: 8px 16px;
  font-family: "Museo Sans";
  color: #0C111A;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.input-message:focus{
  border: 1px solid #FAFAFA;
  outline: none !important;
}

.input-message::placeholder{
  color: #B0BAC9;
}

.contact-button{
  color: #FAFAFA;
  background-color: #114FCB;
  border-radius: 4px;
  width: auto;
  position: relative;
  display: flex;
  height: 56px;
  justify-content: center;
  text-transform: capitalize;
  margin: 0 40px 32px;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.contact-button:hover{
  background-color: #00349E;
}

.success-message{
  position: relative;
  z-index: 2;
  background-color: #FFFFFF;
  height: fit-content;
  width: 672px;
  margin: auto;
  border-radius: 4px;
  display: none;
  flex-direction: column;
  z-index: 101;
  padding: 32px;
}

.success-icon{
  height: 48px;
  width: 48px;
  position: relative;
}

@media screen and (min-width: 320px) and (max-width:767px) {
  .contact-form{
    width: auto;
    margin: 32px 16px 16px;
    padding: 64px 24px 24px;
    overflow-y: scroll;
  }
  .contact-header{
    margin: 0 8px 0;
  }
  .contact-message{
    padding: 0;
  }

  .forms-container{
    flex-direction: column;
    margin: 0;
  }

  .contact-column.message{
    margin: 32px 0 0;
  }
  .contact-input{
    width: calc(100% - 32px);
  }
  .contact-button{
    margin: 24px 0 0;
  }

  .input-message{
    height: 140px;
  }

  .success-message{
    width: auto;
    margin: auto 16px;
  }
}
