.geography-section{
  text-align: center;
  background-color: transparent;
  margin-bottom: 228px;
}

.geography-content{
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
}

.cities-list{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
}

.city-container{
  width: calc((100% - 32px) / 3);
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
}

.city-button{
  width: 100%;
  background-color: rgba(250,250,250,.2);
  padding: 12px 16px;
  text-transform: capitalize;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  font-weight: 700;
  flex-direction: row;
  justify-content: space-between;
}


.city-chevron{
  height: 24px;
  width: 24px;
  transform: rotate(180deg);
  align-self: center;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.city-chevron.expanded{
  transform: rotate(0);

}

.city-content{
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0px 16px;
  height: 0;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  text-transform: capitalize;
  text-align: left;
  background-color: rgba(250,250,250,.2);
  color: #BFC5D2;
}

.city-content.expanded{
  height: fit-content;
  padding: 12px 16px;
}

.map-container{
  width: 100%;
  display: flex;
  flex-direction: column;

}
.map-view-container{
  width: 100%;
  position: relative;
  border-radius: 1%;
  height: fit-content;
}

.map-view-container:before{
  content: "";
	display: block;
	padding-top: 56.25%;
}

.map-view{
  position: absolute;
  display: flex;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
  background-color: transparent;
}

.map-legend{
  width: 33%;
  height: 4px;
  margin: 40px auto 18px;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  border-radius: 4px;
  background: -webkit-linear-gradient(left, #49C4E8 , #3C62C9);
  background: -o-linear-gradient(right, #49C4E8, #3C62C9);
  background: -moz-linear-gradient(right, #49C4E8, #3C62C9);
  background: linear-gradient(to right, #49C4E8 , #3C62C9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: capitalize;
}

.legend-value{
  position: relative;
  left: 0;
  top: 15px;
  height: 14px;
  text-transform: uppercase;
  height: fit-content;
}


.mapboxgl-canvas{
  position: static !important;
}

.marker{
  width: 2.5em;
  height: 2.5em;
  display: block;
  cursor: pointer;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .geography-content{
    flex-direction: column;
  }



  .cities-list{
    width: auto;
    padding: 0;
    flex-direction: column;
  }

  .city-container{
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }

  .city-container:last-child{
    margin-bottom: 0;
  }

  .city-button{
    margin: 0;
  }

  .map-container{
    width: 100%;
  }
  .map-view-container{
    width: auto;
    padding: 0;
  }

  .map-view-container:before{
    content: "";
  	display: block;
    padding-top: 70%;
  }

  .map-view{
    left: 0;
    width: 100%;
  }

  .map-legend{
    bottom: 0;
  }
}
