.unsupported-view{
  width: auto;
  height: 100vh;
  overflow: hidden;
  background-color: #0C111A;
  padding: 0 10%;
}

.unsupported-navigation{
  margin: 5% 0;
  width: auto;
  display: flex;
  justify-content: center;
}
