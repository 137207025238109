.segment-view{
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: block;
  background-color: transparent;
}

.top-bar{
  width: 100%;
  height: fit-content;
  height: -webkit-fit-content;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  top: 0;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.top-bar.hidden{
  top: -70px;
}

.top-bar.filled{
  background-color: #0C111A;
  position: fixed;
}

.segment-background{
  z-index: -1;
  position: fixed;
  top: -60px;
  left: -60px;
  height: calc(100vh + 120px);
  width: calc(100vw + 120px);
}

.segment-image{
  width: auto;
  min-width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

.segment-overlay{
  opacity: .6;
  background-color: #131A27;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sections-views-container{
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

.section-view{
  width: 100%;
  height: fit-content;
  height: -webkit-fit-content;
  position: relative;
  display: flex;
  opacity: 1;
  flex-direction: column;
  text-align: center;
  z-index: 0;
  background-color: #0C111A;
}

.section-content{
  width: auto;
  max-width: 850px;
  padding-top: 104px;
  margin: 0 auto;
  position: relative;
}

.section-content.wide{
  max-width: 1150px;
  padding: 0;
}

.section-view .header{
  width: 100%;
  margin-bottom: 32px;
  text-transform: capitalize;
}

.section-view .description{
  text-align: left;

}

.drawer-view{
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  bottom: -226px;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.commerce-table-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  row-gap: 20px;
  margin-top: 10px;
  margin-bottom: 80px;
}

.commerce-col-wrapper:last-child {
  border-right: solid;
  border-right-color: #3C4047;
  border-right-width: 1px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.commerce-col-wrapper:first-child {
  border-left: solid;
  border-left-color: #3C4047;
  border-left-width: 1px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.commerce-col-wrapper:nth-child(4n){
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #3C4047;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.commerce-col-wrapper:nth-child(5n){
  border-left: solid;
  border-left-width: 1px;
  border-left-color: #3C4047;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}


.commerce-col-wrapper {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;  
  flex-direction: column;
  min-width: 140px;
  border-bottom: solid;
  border-bottom-color: #3C4047;
  border-bottom-width: 1px;
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #3C4047;
}

.commerce-header {
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: #3C4047;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  height: 60px;
  padding-left: 10px;
}

.commerce-header .commerce-caret {
  display: none
}


.commerce-example {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #DADADA;
  text-align: left;
  height: 50px;
  border-style: solid;
  border-color: #3C4047;
  border-width: 1px;
  padding-left: 10px;
}

#commerce-drivers-no-data {
  width: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .section-content{
    width: auto;
    max-width: 320px;
    padding:  0 24px;
  }

  .section-content.wide{
    width: auto;
    max-width: 320px;
    padding: 0 24px;
  }


  .commerce-table-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 2px;
  }

  .commerce-header {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    border-bottom: solid;
    border-color: #4E525A;
  }

  .commerce-table-wrapper :last-child .commerce-header {
    border-color: #3C4047;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .commerce-table-wrapper :first-child .commerce-header {
    border-top-color: #3C4047;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .commerce-col-wrapper {
    border-bottom: none;
    border-top: none;
  }

  .commerce-col-wrapper:last-child {
    border-right: none;
  }
  
  .commerce-col-wrapper:first-child {
    border-left: none;
  }

  .commerce-col-wrapper:nth-child(4n){
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .commerce-col-wrapper:nth-child(5n){
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: none;
  }

  .commerce-example {
    height: 0px;
    border: none;
    overflow: hidden;
    transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }

  .commerce-example.open {
    height: 50px;
    transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    border-style: solid;
    border-color: #3C4047;
    border-width: 1px;
  }

  .commerce-header .commerce-caret {
    display: inline-block;
    transform: rotate(180deg);
    transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }

  .commerce-header .commerce-caret.open {
    display: inline-block;
    transform: rotate(0deg);
    transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }




}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .section-content{
    max-width: 700px;
  }

  .section-content.wide{
    max-width: 700px;
      padding: 0;
  }

  .section-view .description{
    margin: 32px 0 40px;
  }

  #drivers_desc,#opportunities_desc {
    margin-top: 0px;
    margin-bottom: 20px;
  }


}


@media screen and (min-width: 320px) and (max-width: 1439px) {
  .section-content{
    padding-top: 80px;
  }

  .top-bar.hidden{
    top: -58px;
  }

  .tab-container{
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-tab{
    width: fit-content;
    margin: 8px auto;
  }

  .segment-control{
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 !important;
  }

  #commerce-drivers-no-data {
    width: 100%;
  }
}
