.characteristics-section{
  text-align: center;
  padding: 0 !important;
  max-width: none;
  background-color: #0C111A;

}

.insights-divider{
  width: auto;
  left: 0;
  background-color: #131A27;
  padding: 24px 0 40px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.insights-copy{
  width: 850px;
}

.characteristics-table{
  display: flex;
  position: relative;
  width: auto;
  flex-direction: column;
}

.characteristics-row{
  width: auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 52px 0;
  max-width: 1440px;
  position: relative;
}

.characteristics-title{
  margin-bottom: 40px;
  width: auto;
  text-transform: uppercase;
  position: relative;
  display: flex;
}

.characteristics-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.characteristics-container.reverse{
  flex-flow: row-reverse;
}

.characteristics-content{
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 16px);
}

.type-visual{
  height: fit-content;
  animation-delay: 375ms;
}

.type-profession{
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.chart-header{
  text-transform: uppercase;
  width: 50%;
  margin-bottom: 8px;
}

.chart-value{
  width: 50%;
  text-transform: uppercase;
}

.characteristics-content .right{
  text-align: right;
}

.chart-value.avg{
  width: fit-content;
}

.primary-bar{
  height: 8px;
  margin: 0;
  position: relative;
  transition: all 750ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.primary-bar.left{
  border-radius: 4px 0 0 4px;
}

.primary-bar.right{
  border-radius: 0 4px 4px  0;
}

.background-bar{
  width: 100%;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.4) ;
}

.moe-chart{
  width: 100%;
  height: 8px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin: 18px 0 9px;
}

.moe-axis{
  width: 100%;
  height: 8px;
  background-color: #2E384D;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.moe-value{
  width: 2px;
  height: 8px;
  position: absolute;
  background-color: #FAFAFA;
}

.moe-value.median{
  left: 0;
  right: 0;
  bottom: 1px;
  height: 0;
  width: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-color: transparent;
  border-top: 6px solid #B0BAC9;;
  color: blue;

  margin: 0 auto;
}

.moe-value.bar{
  height: 8px;
  bottom: 0;
  left: 0;
  margin: auto;
}

.moe-value.avg{
  position: relative;
  height: 0;
  width: 0;
  margin: 0 auto;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-color: transparent;
  border-top: 6px solid #B0BAC9;;
}

.average-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 200px;
  text-align: center;
  bottom: 1.9px;
  justify-content: center;
}

.average-container.stacked{
  width: 180px !important;
  max-width: 180px;
  bottom: 16px;
  z-index:3;
}

.average-title{
  text-transform: uppercase;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
  white-space: nowrap;
}

.legend-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.middle-legend{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 42px;
  width: fit-content;
  margin: 0 auto;
}

.chart-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  align-content: flex-end;
}

.chart-content.bar{
  position: relative;
  height: fit-content ;
  align-content: flex-start;
}

.chart-content.legend{
  margin-top: 8px;
  height: fit-content;
}

.bar-chart-container{
  width: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  margin: 32px 0 0 !important;
  border-radius: 4px;
  overflow: visible;
}

.characteristics-insight{
  display: flex;
  flex-direction: row;
}

.svg-container{
  width: 100%;
  height: 100%;
}

.svg-container:before{
  content: "";
	display: block;
	padding-top: 100%;
}

.svg-path{
  position: relative;
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000;
 }

.draw-path{
  animation: dash 3s linear forwards;
}


 @keyframes spin {
   from: {
     transform: rotate(0);
   }
   to{
     transform: rotate(360deg)

   }
 }

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .characteristics-row{
    padding: 16px 0;
  }

  .insights-copy{
    width: 320px;
  }
  .chart-content-bar{
    margin: 0;
  }
  .average-container{
    bottom: 16px;
  }

  .average-container.stacked{
    bottom: 30px;
  }

  .average-title{
    margin-bottom: 2px;
  }

  .chart-value{
    margin-bottom: 30px;
  }

  .chart-value.avg{
    margin-bottom: 0;
  }
  .characteristics-copy{
    margin-top: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .insights-copy{
    width: 700px;
  }
  .characteristics-row{
    padding: 40 16px;
  }

  .chart-content{
    padding-bottom: 48px;
  }

  .chart-content.bar{
    padding-bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 1439px) {

  .characteristics-container{
    width: auto;
    flex-flow: column-reverse !important;
    flex-wrap: wrap !important;
  }

  .characteristics-container.fixed{
    flex-flow: column !important;
  }

  .characteristics-container.reverse{
    flex-flow: column-reverse !important;
  }

  .characteristics-row{
    width: auto;
    flex-direction: column;
  }

  .characteristics-content{
    width: 100%;
  }

  .characteristics-copy{
    padding: 0 ;
  }

  .chart-header{
    text-align: left;
  }

  .chart-value{
    text-align: left;
  }

  .chart-content.bar{
    margin: 20px 0;
  }

  .type-visual{
    width: fit-content !important;
  }
}






