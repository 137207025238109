.segment-control{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
  background-color: #0C111A;
}

.segment-control .navigation{
  width: 50vw;
  position: relative;
  height: fit-content;
  overflow: hidden;
}

.segment-control .navigation:before{
  padding-top: 56.25%;
  display: block;
  content: "";
}

.segment-control .content{
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  position: absolute;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: auto;
  color: #FAFAFA;
}

.thumbnail-description{
  padding: 0 20%;
  opacity: 0;
  height: 0px;
  max-height: 96px;
  bottom: -12px;
  transition: all 750ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
}


.segment-control .content:hover >.thumbnail-description{
  padding-top: 24px;
  opacity: 1;
  bottom: 0;
  height: inherit;
}

.segment-control .navigation-image{
  position: absolute;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 750ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.segment-control .navigation:hover > .navigation-image{
  filter: blur(5px);
  transform: scale(1.1, 1.1);
}

.swipe{
  width: 100vw;
  height: 100vh;
  bottom: 0;
  position: fixed;
  background-color: black;
  z-index: 10;
  display: none;
  animation-duration: .75s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.swipe.left-right{
  left: -100vw;
  animation-name: left-right;
  display: inherit;
}

.swipe.right-left{
  right: -100vw;
  animation-name: right-left;
  display: inherit;
}

@keyframes left-right {
  0%{
    left: -100vw;
  }
  30%{
    left: 0;
  }

  70%{
    left: 0;
  }

  100%{
    left: 100vw;
  }
}

@keyframes right-left {
  0%{
    right: -100vw;
  }
  30%{
    right: 0;
  }

  70%{
    right: 0;
  }

  100%{
    right: 100vw;
  }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
  .segment-control{
    padding: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .segment-control{
    justify-content: center;
    flex-direction: column;
  }

  .segment-control .navigation{
    width: 100%;
    height: 56.25vw;
  }
}
