.grid-view{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #0C111A;
  z-index: 10;
  justify-content: center;
  display: flex;
  flex-direction: column;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.grid-view.home{
  height: fit-content;
  position: relative;
}

.grid-row{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 26px;
}

.grid-row-category{
  min-width: fit-content;
  overflow-y: hidden;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  margin: 0;
  align-content: center;
  align-items: center;
  bottom: inherit;
}


.grid-row-category .section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 24px 0 ;
}

.grid-row-category .section .label{
  text-transform: uppercase;
  padding: 5px;
  width: fit-content;
  width: -webkit-fit-content;
  margin: 0 10px;
  text-align: center;
  position: relative;
  display: flex;

}


.grid-row-category .section .divider{
  background-color: #FAFAFA;
  opacity: .4;
  position: relative;
  height: 2px;
  flex: 1;
  display: flex;
  align-self: center;
  width: 1px;
}

.grid-items-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  flex-wrap: wrap;
  max-width: 1180px;
  margin: 0 auto;
}

.grid-item{
  background-color: transparent;
  position: relative;
  overflow: hidden;
  color: #FAFAFA;;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 32px;
  align-content: center;
}

.grid-content{
  height: 220px;
  max-height: 220px;
  width: 220px;
  max-width: 220px;
  background-color: transparent;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 4px;
}


.grid-label{
  position: relative;
  opacity: .8;
  height: fit-content;
  min-height: 36px;
  text-transform: capitalize;
  z-index: 1;
  text-align: center;
  background-color: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  overflow: hidden;
}

.grid-body{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  color: rgba(255, 255, 255,0.8);
  background-color: rgba(19,26,39,.6);
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  align-items: center;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.grid-body:hover{
  opacity: 1;
}

.grid-description{
  bottom: -12px;
  position: relative;
  overflow-y: hidden;
  text-align: center;
  display: inline-block;
  height: fit-content;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.grid-body:hover > .grid-description{
  bottom: 0;
}

.grid-image{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  object-fit: cover;
  align-self: center;
  transition: all .5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.grid-item .grid-content:hover > .grid-image{
  filter: blur(5px);
  transform: scale(1.1, 1.1);
}

.buttons-container{
  position: absolute;
  top: 24px;
  right: 12px;
  margin: 0 12px;
  display: flex;
  z-index: 3;
}

.toolbar-button{
  width: 24px;
  height: 24px;
  background-color: transparent;
  color: #FFFFFF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 12px;
  position: absolute;
  top: 24px;
  right: 24px;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.toolbar-button:hover{
  opacity: .4
}

.grid-toolbar{
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex: auto;
  flex-direction: row;
  background-color: #161F2E;
  justify-content: space-around;
}

.categories-menu{
  position: relative;
  display: inline-block;
}

.dropdown-container{
  width: 250px;
  height:  40px;
  padding: 0;
  z-index: 10;
  top: 0;
  bottom: 0;
  margin: auto;
}

.dropdown-button{
  width: 100%;
  height: 100%;
  float: left;
  text-align: left;
  background-color: #1D2432;
  color: #FFFFFF;
  text-transform: capitalize;
  padding: 11px 16px;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.dropdown-button:focus{
  color: #FFFFFF;
  outline: none !important;
  box-shadow: none;
  border-radius: 4px 4px 0 0;
}

.dropdown-menu{
  width: auto;
  border-radius: 0 0 4px 4px;
  border: none;
  box-shadow: 0 10px 20px 0 #0C111A;
  margin-top: 2.5em;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #1D2432;
  padding: 0;
  height: 0;
  transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.dropdown-menu.expanded{
  height: 200px;
}

.dropdown-menu.expanded > .background-cover.grid{
  display: flex;
}

.dropdown-menu>li{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  padding: 8px 16px;

}

.dropdown-menu>li:hover{
  background-color: #1C355D !important;
  color: #FFFFFF;
}

.dropdown-menu>li:focus{
  background-color: #1C355D !important;
}

.caret{
  position: absolute;
  right: .5em;
  height: 24px;
  width: 24px;
  transform: rotate(180deg);
  transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.dropdown-button:focus> .caret{
  transform: rotate(0deg);
  transition: all .375s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .grid-items-container{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 940px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-items-container{
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 700px;
  }
}

@media screen and (max-width: 767px) {
  .grid-items-container{
    grid-template-columns: 1fr 1fr;
    max-width: 320px;
  }

  .grid-content{
    height: 152px;
    max-height: 152px;
    width: 152px;
    max-width: 152px;
  }

  .grid-toolbar{
    min-height: 72px;
    height: 72px;
  }

  .grid-toolbar .categories-container{
    display: none;
  }
  .grid-row-category{
    padding: 0;
  }

  .grid-row-category .section{
    margin: 24px 0;
  }

  .grid-item{
    max-width: 152px;
    overflow: hidden;
  }

  .grid-item .grid-content:hover > .grid-image{
    filter:none;
    height: 100% !important;
  }

  .grid-body{
    display: none !important;
  }

  .grid-label{
    max-width: 152px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-control{
    display: none;
  }

  .dropdown-container{
    margin-left: 24px;

  }

  .dropdown-button{
    width: 250px;
  }
}
