button{
  border: none;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}
button:focus{
  outline: none !important;
}

button:hover{
  cursor: pointer;
}

body{
  overflow: hidden;
  width: 100%;
  height: 100vh;
  color: #FFFFFF;
  position: relative;
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.background-cover{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(12,17,26,.8);
  width: 100vw;
  height: 100vh;
  display: flex;
}

.background-cover.grid{
  display: none;
  position: fixed;
  background-color: transparent;
}

.image-overlay{
  opacity: .5;
  background-color: #131A27;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.loading-conatiner{
  z-index: 1;
  position: absolute;
  background-color: #0C111A;
  width: 100vw;
  height: 100%;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}

.loading-icon{
  width: 70px;
  height: 70px;
  margin-top: 45vh
}

.sp-h-0{
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  position: relative;
}

.sp-h-1{
  font-size: 80px;
  line-height: 96px;
  font-weight: 700;
  position: relative;
}

.sp-h-2{
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.sp-h-3{
  font-size: 28px;
  line-height: 40px;
  font-weight: 300;
  position: relative;
  text-transform: uppercase;
  position: relative;
}

.sp-h-4{
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}

.sp-h-5{
  font-size: 12px;
  line-height: 130%;
  font-weight: 500;
  text-transform: uppercase;
}

.sp-p-1{
  font-size: 21px;
  line-height: 32px;
  font-weight: 300;
  position: relative;
}

.sp-p-2{
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  color: #FAFAFA;
}

.sp-p-3{
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}

.sp-p-4{
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  color: #B0BAC9;
}

.sp-c-1{
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1.35px;
  color: #BFC5D2;
  font-weight: 500;
}

.sp-c-2{
  font-size: 12px;
  letter-spacing: 1.13px;
  color: #B0BAC9;
  font-weight: 500;
  line-height: 14px;
}

.sp-b-1{
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
}

.sp-b-2{
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
}

.sp-i-1{
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(255,255,255, 0.5);
}


.icon{
  height: 24px;
  width: 24px;
  position: relative;
  margin-right: 8px;
}

.icon.alert{
  height: 16px;
  width: 16px;
  background-color: transparent;
}

.guide-arrow{
  width: 1px;
  height: 56px;
  margin: 24px auto 0;
  background-color: #B0BAC9;
  transition: all .4s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.ani-1{
  opacity: 0;
}

.ani-2{
  transform: scale(1, 0);
}

.ani-3{
  transform: scale(0, 1);
}

.trans-1{
  width: 50% !important;
}

.grow{
  animation-duration: 750ms;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

.grow.y{
  animation-name: grow-y;
}

.grow.x{
  animation-name: grow-x;
}

.slide-up{
  animation-name: slide-up;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

.slide-in{
  animation-duration: 1.25s;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

.fade-in{
  animation-name: text-fade-in;
  animation-duration: 750ms;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

@media screen and (min-width: 768px) {
  @keyframes grow-x {
    from{
      transform: scale(0, 1);
    }
    to{
      transform: scale(1, 1);
    }
  }

  @keyframes grow-y {
    from{
      transform: scale(1, 0);
    }
    to{
      transform: scale(1, 1);
    }
  }


  @keyframes text-fade-in {
    from{
      opacity: 0;
      bottom: -12px;
    }
    to{
      opacity: initial;
      bottom: 0;
    }
  }

  @keyframes slide-up {
    from{
      top:100%;
    }
    to{
      top: 0;
    }
  }

  @keyframes slide-in {
    0%: {
      width: 0px;
    }
    100%{
      width: inherit;
    }
  }

  @keyframes fadeOut {
    from{
      opacity: 1
    }
    to{
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
}


.title-navigation{
  position: absolute;
  width: 100vw;
  height: 72px;
  border-bottom: 1px solid #FFFFFF;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
  background-color: transparent;
}

.title-navigation.bottom{
  top: initial;
  bottom: 0;
  border-bottom: none;
  border-top: 1px solid #FFFFFF;
  position: relative;
}

.segment-image-container{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

.gradient{
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to right, rgba(28,38,54,.75), rgba(90,113,148,.75));
}

.segment-image{
  height: 100%;
  width: 100%;
  background-size: contain;
  position: absolute;
  top: 0;
}

.error-message{
  margin: 8px 0;
  width: 100%;
  max-width: 272px;
  height: fit-content;
  text-align: center;
  color: #CF444F;
  display: none;
}

.transition-out{
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 1.3s;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

.transition-in{
  opacity: 1;
  animation-duration: 1.3s;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  animation-fill-mode: forwards;
}

.caret{
  position: relative;
  margin: auto 0;
  height: 20px;
  width: 20px;
  color: #FFFFFF;
  background-size: contain;
}

.caret.right{
  transform: rotate(90deg);
}

.caret.left{
  transform: rotate(-90deg);
}

.link{
  text-decoration: none;
  border-bottom: 1px solid #114FCB;
  padding-bottom: 4px;
  color: #FFFFFF;
  transition: all .3s cubic-bezier(0.465, 0.183, 0.153, 0.946);

}

.link:hover{
  color: #114FCB;
}

@media screen and (max-width: 1439px) {
  .segment-image{
    width: auto;
    background-size: cover;
    position: fixed;
    background-position: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .sp-h-1{
    font-size: 48px;
    line-height: 56px;
  }

  .sp-p-1{
    font-size: 16px;
    line-height: 26px;
  }

  .sp-b-1{
    font-size: 16px;
    line-height: 21px;
  }

  .sp-c-1{
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.12px;
  }

  .ani-1{
    opacity: initial;
    bottom: 0;
  }

  .ani-2{
    transform: scale(1, 1);
  }

  .ani-3{
    transform: scale(1, 1);
  }

  .ani-4{
    width: inherit;
  }

  .trans-1{
    width: unset !important;

  }
}
