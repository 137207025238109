.topics-section{
  background-color: transparent;
}
.topics-container{
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  margin: 40px 0;
}

.topic-box{
  width: auto;
  padding: 10px 26px;
  white-space: nowrap;
  border-radius: 4px;
  text-transform: capitalize;
  margin: 12px 11px;
  display: flex;
  border-image: linear-gradient(to right, #49C4E8 , #3C62C9) 1 ;
  border-style: solid;
  border-width: 2px;
  border-image-slice: 1;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .topics-container{
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .topic-box{
    width: fit-content;
    margin: 12px auto;
  }
}
