.time-section .section-content{
  padding-top: 54px;
}
.info-container{
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 108px;
}

.time-table{
  width: 100%;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 66px;
}

.time-cover{
  background-color: #0C111A;
  width: 100%;
  height: calc(100% - 26px);
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 100%;
  transition: none !important;
}

.time-cover.time-reveal{
  animation-name: time-reveal;
  left: 0;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.1, 0.946);
  animation-fill-mode: forwards;
}

.time-details{
  width: 20%;
  text-align: justify;
}

.table-divider{
  text-transform: uppercase;
  text-align: center;
  padding-top: 12px;
  position: relative;
}

.time-table canvas{
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.tab-container{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px 0 40px;
  border-radius: 4px;
  align-self: center;
  overflow: hidden;
  flex-wrap: nowrap;
}

.time-tab{
  text-align: center;
  text-transform: capitalize;
  padding: 0 24px;
  background-color: rgba(250,250,250,.2) ;
  color: #FAFAFA;
  width: calc(33.33% - 2px);
  border: none;
  height: 40px;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.time-tab:hover{
  background-color: rgba(250,250,250,.4); ;
  color: #FAFAFA;
}

.time-tab.selected{
  background-color: #FAFAFA ;
  color: #0C111A;
}

@keyframes time-reveal {
  from{
    left: 0;
  }
  to{
    left: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .tab-container{
    flex-direction: column;
    height: fit-content;
  }
  .time-tab{
    width: auto;
    margin: 8px 0;
  }

  .time-cover{
    height: 100%;
  }

  .table-divider{
    writing-mode: vertical-lr;
    text-align: left !important;
    padding-top: 0 !important;
    display: flex;
    align-items: center;
  }

}
