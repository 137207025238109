.overview-section{
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: transparent;
}

.overview-content {
  width: 850px;
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.overview-guide{
  position: absolute;
  width: fit-content;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  height: fit-content;
  overflow: hidden;
}

.guide-text{
  text-transform: uppercase;
  display: flex;
  position: relative;
  top: 0;
  white-space: nowrap;
  transition: all .4s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}


@media screen and (min-width: 768px) and (max-width: 1439px) {
  .overview-content {
    width: 700px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .overview-content {
    width: auto;
    max-width: 328px;
    padding: 0 24px;
  }
}
