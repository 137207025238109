.nav-bar{
  width: auto;
  height: fit-content;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  margin: 16px 8px 34px 16px;
  justify-content: space-between;
}

.nav-container{
  display: flex;
  flex-direction: row;
  position: relative;
}

.nav-button{
  border-radius: 4px;
  text-transform: capitalize;
  padding: 8px 16px 8px 8px;
  margin: 0 8px;
  cursor: pointer;
  color: #FFFFFF;
  align-content: center;
  display: flex;
  line-height: 24px;
  background-color: rgba(250,250,250,.2);
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.nav-button:hover{
  background-color: rgba(250,250,250,.4);
}

.nav-menu{
  margin-left: 24px;
  display: none;
}

.logo{
  height: 24px;
}

.logo:hover{
  opacity: .75;
}

.home-button{
  background-color: transparent;
  background-image: url("/img/misc/grid.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  padding: 0;
  opacity: .75;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  transition: all .2s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  right: 0;
  color: #FFFFFF;
}

.home-button:hover{
  opacity: 1;
}

.nav-container .share-container{
  width: fit-content;
  height: fit-content;
  position: absolute;
  background-color: grey;;
  z-index: 1;
  display: none;
  flex-direction: row;
  bottom: calc(-100% - 24px);
  justify-content: space-between;
  left: calc(-50% + 32px);
}
@media screen and (min-width: 320px) and (max-width: 767px) {

  .nav-button{
    padding: 0;
    background-color: transparent
  }

  .nav-button:hover{
    background-color: transparent
  }

  .nav-button:focus{
    opacity: .75;
    outline: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .nav-button{
    padding: 8px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .nav-bar{
    margin: 16px;
  }

  .nav-title{
    display: none;
  }


  .nav-button .icon{
    margin-right: 0;
  }


  .navigation{
    width: auto;
    margin: 0 !important;
  }

  .home-button{
    position: relative;
    margin: auto 0;
  }
}
