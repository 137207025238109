.commerce-section{
  padding-bottom: 104px;
  background-color: #0C111A;
}

.commerce-section .title{
  text-transform: uppercase;
  margin: 64px auto 32px;
}

.table-container{
  width: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  overflow: hidden;
}

.table-container.brands{
  border-radius: 4px;
}

.table{
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100% - 54px);
}

.table.correlations{
  border: 2px solid rgba(255,255,255, .2);
  padding: 25px;
  flex-direction: row;
  border-radius: 4px;
  justify-content: center;
}

.table.correlations.brands{
  background-color: #FFFFFF;
}


.table-header{
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 9px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  width: 100%;
}

.table-body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  justify-content:flex-start;
}

.table-body.brands{
  justify-content: space-around;
}

.table-item{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  text-transform: capitalize;
  align-items: center;
  margin-bottom: 8px;
}

.spacer{
  font-size: 10px;
  margin: 0 8px;
}

.positive-img{
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #78E37C;
  top: 0;
  bottom:0;
  margin: auto 16px auto 0;
  position: relative;
  padding: 4px;
}

.brand-item{
  position: relative;
  display: flex;
  align-items: center;
  margin: 33px;
}

.brand-img{
  position: relative;
  max-height: 150px;
  max-width: 250px;
}

.no-brands{
  margin: 127px auto;
  width: 372px;
}

.price-container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: fit-content;
  width: 100%;
  position: relative;
}

.dollar-container{
  height: 68px;
  width: 51px;
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 8px 8px;
  border-radius: 4px;
}

.dollar-img{
  position: relative;
  height: 100%;
  width: 51px;
  z-index: 1;

}

.dollar-background{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
}


@media screen and (min-width: 0px) and (max-width: 767px) {
  .table-body:first-child{
    margin-bottom: 24px;
  }
  .table-body.brands{
    justify-content: center;
    margin-bottom: 0;

  }

  .table.correlations{
    flex-direction: column;
  }

  .no-brands{
    margin: 25px;
    width: auto;
  }
}

@media screen and (min-width: 0) and (max-width: 1439px) {

  .table-container{
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .table{
    width: 100%;
    margin-bottom: 32px;
    padding: 25px;
  }
  .table.correlations.brands{
    padding: 0;
  }

  .dollar-background{
    top: 0;
  }
}
